import React from "react";
import "./App.css";
import Home from "./Pages/Home";
import {Routes, Route, BrowserRouter as Router} from 'react-router-dom'
import Login from "./Pages/Login";
import PostItems from "./Pages/PostItems";
import Products from "./Pages/Products";
import Order from "./Pages/Order";
import SingleProduct from "./Pages/SingleProduct";
import SingleOrder from "./Pages/SingleOrder";
import Inventory from "./Pages/Inventory";
import UserReg from "./Pages/UserReg"
const App = () => {
  return (
    <Router>
    <div className="App">
     <Routes>
    <Route path="/home" element={ <Home/>}/>
    <Route path="/" element={ <Login/>}/>
    <Route path="/add-products" element={ <PostItems/>}/>
    <Route path="/all-products" element={<Products/>} />
    <Route path="/orders" element={<Order/>} />
    <Route path='/items/:id' element={<SingleProduct/>}/>
    <Route path='/orders/:id' element={<SingleOrder/>}/>
    <Route path="/inventory" element={<Inventory/>}/>
    <Route path="/user-reg" element={<UserReg/>}/>
    </Routes>
    </div>
    </Router>
  );
};

export default App;
