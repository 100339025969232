import React,{useState, useEffect} from 'react'
import SideBar from '../Components/SideBar/SideBar';
import {Grid, Box, Typography, Select, TextField, InputLabel,
   MenuItem, Button} from '@mui/material';
import axios from '../Components/axios'
import { toast, ToastContainer } from 'react-toastify';

const UserReg = () => {
  const url= '/users'
  const[role, setRole]  = useState([])
  const[option, setOption] = useState('')
  const[errMsg, setErrMsg] = useState('')
  const[input, setInput] = useState({
    first_name: '',
    last_name:'',
    email:"",
    password:'',
    pin:'',
    phone_number:'', 
    role:''
  })

  const changeHandler=(e)=>{
    const name =e.target.name;
    const value= e.target.value;
    setInput({...input, [name]:value})
  }

  const roleHandler =(e)=>{
    setOption(e.target.value)
  }

  const fetchRole = async ()=>{
    const savedUser = localStorage.getItem('user')
    await axios('roles', 
        {headers:{
        Authorization: `Bearer ${JSON.parse(savedUser).token}`
    }}
    )
     .then((response) => {
      const roles= response?.data
      setRole(roles)
     })
     .catch( (error) => {
     setErrMsg(error.message)
 })
}
  const submitHandler = (e)=>{
    e.preventDefault()
    const savedUser = localStorage.getItem('user');
    const toastOptions ={
      autoClose: 500,
      pauseOnHover:true
   }
    const data={
      first_name: input.first_name,
      last_name: input.last_name,
      email: input.email,
      phone_number: input.phone_number,
      role: option,
      pin: input.pin,
      store_id: JSON.parse(savedUser).store_id,
      password: input.password
    }
    axios.post(url, data, {headers:{
      'Accept': 'application/json',
      Authorization: `Bearer ${JSON.parse(savedUser).token}`
    }}).then((response)=>{
      toast('User Registered Successfully', toastOptions)
    }).catch((error)=>{
      setErrMsg(error)
      console.log(error)
    })
  }

  useEffect(()=>{
    fetchRole()
  }, [])

  return (
    <Grid container sx={{display:'flex', justifyContent:'space-between'}}>
        <Grid item xs={2}>
        <SideBar/>
        </Grid>
        <Grid item xs={9}>
        <form onSubmit={submitHandler}>
            <Typography variant='h4' component='h4' sx={{textAlign:'center', mt:"3rem", color:'#fff'}}>
                Register A User
            </Typography>
            { errMsg ? 
            <Typography variant='p' component='p' sx={{color:'red', p:'5px', textAlign:'center'}}>
                {errMsg}
            </Typography> : ''
            }
        <Grid rowSpacing={3} sx={{p:'5px',}} columnSpacing={3} container>
            <Grid item xs={5}>
            <TextField fullWidth label="First Name" onChange={changeHandler} id="first_name" 
            name='first_name'
             value={input.first_name}/>
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Last Name" onChange={changeHandler} id="last_name" 
           value={input.last_name} name='last_name'/>
           </Grid>
            <Grid item xs={5}> 
            <TextField fullWidth label="Email" onChange={changeHandler} id="email"
             name="email" value={input.email}/>
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Phone Number" onChange={changeHandler} id="phone_number" 
           value={input.phone_number} name="phone_number"/>
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="pin" onChange={changeHandler} id="pin" 
           value={input.pin} name='pin' type="password" />
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Password" onChange={changeHandler} id="password" name='password'
            value={input.password} type="password" />
            </Grid>
            {/* <input onChange={changeHandler} id="store_id"
             name='store_id'
            value={input.store_id} type="hidden" /> */}
            <Grid item xs={5}>
            <InputLabel id="role">Role</InputLabel>
                <Select
                    labelId="role"
                    id="role"
                    name='role'
                    label="Role"
                    value={option}
                    onChange={roleHandler}
                    sx={{width:'100%',}}
                >
                    {role.map((rol, index)=>{
                        return <MenuItem value={index} key={index}> {rol}</MenuItem>
                    })}
                </Select>
             </Grid>
        </Grid>
            <Box sx={{justifyContent:'center', textAlign:'center'}}>
            <Button type='submit' variant='contained' color='success' sx={{my:2}}>
                Post Item
            </Button>
            </Box>
        </form>
        </Grid>
        <ToastContainer/>
    </Grid>
  )
}

export default UserReg