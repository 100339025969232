import React,{useState, useEffect} from 'react'
import { TableContainer, Table, Paper, TableHead, TableBody,
     TableRow, TableCell, Grid, Typography, TablePagination, Box, TextField} from '@mui/material';
import SideBar from '../Components/SideBar/SideBar';
import axios from '../Components/axios';

const Inventory = () => {
    const [loading, setLoading] = useState(true);
    const [errMsg, setErrMsg] = useState('')
    const [pg, setpg] = useState(0);
    const [rpg, setrpg] = useState(5);
    const [order, setOrder]= useState([]);
    const [totalQuantitySold, setTotalQuantitySold] = useState(0);
    const [totalQuantityInStock, setTotalQuantityInStock] = useState(0);
    const [totalAmountSold, setTotalAmountSold] = useState(0);
    const [totalTaxesAmount, setTotalTaxesAmount] = useState(0);
    const [valueOfStockWithOutTaxes, setValueOfStockWithoutTaxes] = useState(0);
    const [valueOfStockWithTaxes, setValueOfStockWithTaxes] = useState(0)
    const [searchOrder, setSearchOrder] = useState(0);

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }
    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }
    const url= 'itemSales'

    const fetchOrder=()=>{
        const savedUser = localStorage.getItem('user');
        axios(url, {headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${JSON.parse(savedUser).token}`
        }})
        .then((invoice)=>{
            const item = invoice.data.data;
            setOrder(item)
            setLoading(false)
            console.log(item)
            setErrMsg('')
        })
        .catch((error)=>{
            setErrMsg(error.message)
        })
    }
    
     const findTotal = ()=>{
        let totalQuantitySold =0;
        let totalQuantityInStock =0;
        let valueOfStockWithOutTaxes=0;
        let totalAmountSold=0;
        let totalTaxesAmount=0;
        let valueOfStockWithTaxes= 0;

        for(let item of order){
            totalQuantitySold= item.quantity + totalQuantitySold;
        }
        for(let item of order){
          totalQuantityInStock = item.remaining_quantity + totalQuantityInStock
        }
        for(let item of order){
            valueOfStockWithOutTaxes = (item.remaining_quantity * item.price )
            + valueOfStockWithOutTaxes
          }
          for(let item of order){
            totalTaxesAmount = parseInt(item.amount/ item.price) + totalTaxesAmount
          }
          for(let item of order){
            totalAmountSold = parseInt(item.amount) + totalAmountSold
          }
          for(let item of order){
            valueOfStockWithTaxes = parseInt(item.price *item.remaining_quantity * item.tax/100)
             + valueOfStockWithTaxes
          }
          setTotalAmountSold(totalAmountSold);
          setTotalQuantityInStock(totalQuantityInStock);
          setTotalQuantitySold(totalQuantitySold);
          setValueOfStockWithTaxes(valueOfStockWithTaxes);
          setValueOfStockWithoutTaxes(valueOfStockWithOutTaxes);
          setTotalTaxesAmount(totalTaxesAmount)
     }

useEffect(()=>{
  findTotal()
}, [order])

    useEffect(()=>{
        fetchOrder()
    }, [])

    const searchInventory = !searchOrder? order : order.filter(order=> order.amount === searchOrder)

  return (
    <Grid container sx={{display:'flex', justifyContent:'space-between', px:'1rem'}}>
    <Grid item sx={{justifyContent:'start'}} xs={2}>
    <SideBar/>
    </Grid>  
    <Grid item xs={9} sx={{my:'3rem'}}>
    <Typography sx={{textAlign:'center', color:'red'}}>{errMsg}</Typography>
    <Typography sx={{textAlign:'center', color:'#fff' }} gutterBottom component="h2" variant="p">
      Inventory Management
      </Typography>
      <Box sx={{display: 'flex', margin: '2rem 0'}}>
        <TextField name='searchOrder' fullWidth id="searchOrder" type="number"
        label="Search By Total Amount" onChange={(e)=>setSearchOrder(e.target.value)}/>
      </Box>
    <Paper>
     <TableContainer component={Paper}>
       <Table sx={{ minWidth: 650, }} aria-label="simple table" >
         <TableHead>
           <TableRow>
             <TableCell>Product ID</TableCell>
             <TableCell align="center">Product Detail</TableCell>
             <TableCell align="center">Quantity Sold</TableCell>
             <TableCell align="center">Quantity In Stock</TableCell>
             <TableCell align="center">Price/Unit</TableCell>
             <TableCell align="center">Amount Sold</TableCell>
             <TableCell align="center">Taxes%</TableCell>
             <TableCell align="center">Reorder Level</TableCell>
             <TableCell align="center">Reorder Quantity</TableCell>
           </TableRow>
       </TableHead>
       {loading ? 
       <TableBody>
       <TableRow>
        <TableCell>Loading... </TableCell>
        </TableRow> 
        </TableBody>:
        <TableBody>
    {searchInventory.slice(pg * rpg, pg * rpg + rpg).map(order=>{
        return (   
        <TableRow key={order.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>{order.id}</TableCell>
            <TableCell>
                {order.name}
            </TableCell>
             <TableCell>{order.quantity}</TableCell>
             <TableCell>{order.remaining_quantity}</TableCell>
             <TableCell>{order.price}</TableCell>
             <TableCell>{order.amount}</TableCell>
             <TableCell>{order.tax}</TableCell>
             <TableCell>{order.quantity}</TableCell>
             <TableCell>{order.quantity}</TableCell>
        </TableRow>
        )
    })}
    </TableBody>
    }
    </Table>
    </TableContainer>
<TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={order.length}
    rowsPerPage={rpg}
    page={pg}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
    />
<Box sx={{display:'flex', justifyContent:'center', flexDirection: 'column', margin:'0 auto'}}>
<Typography>Total Quantity Sold: {totalQuantitySold}</Typography>
<Typography>Total Amount Sold: {totalAmountSold}</Typography>
<Typography>Total Quantity In Stock: {totalQuantityInStock}</Typography>
<Typography>Total Taxes Amount: {totalTaxesAmount}</Typography>
<Typography>Value Of Stock W/O Taxes: {valueOfStockWithOutTaxes}</Typography>
<Typography>Value Of Stock Inc. Taxes: {valueOfStockWithTaxes}</Typography>
</Box>
    </Paper>
  </Grid>
  </Grid>
  )
}
export default Inventory