import React from 'react'
import SideBar from '../Components/SideBar/SideBar';
import MainDash from '../Components/MainDash/MainDash';
import RightSide from '../RightSide/RightSide'

const Home = () => {
    return (
      <div className="app-glass">
      <SideBar />
       <MainDash />
      <RightSide />
    </div>   
    )
}

export default Home