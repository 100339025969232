import React, { useEffect, useRef, useState } from 'react'
import {  useNavigate } from 'react-router-dom';
import axios from '../Components/axios';
import { Grid, Typography, TextField, Button, Box } from '@mui/material';

const loginUrl= '/auth/login-admin'
const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errMsg, setErrMsg] = useState('')
  
  const userRef = useRef('')
  const errRef = useRef('')

  const navigate = useNavigate()
  const submitHandler = async (e)=>{
    e.preventDefault();
    setEmail('')
    setPassword('')
    const data = {
     email: email,
      password : password
    }
   try {

       const resp = await axios.post(loginUrl, data, 
      {
        headers:{'Content-Type': 'application/json; charset=UTF-8', 'Accept': 'Application/json'},
        withCredentials:false,
      }, );

      localStorage.setItem('user', JSON.stringify(resp.data.data) );
      setEmail('')
      setPassword('');
      navigate('home')      
  
} catch (error) {
    console.log(error)
    if (!error?.response) {
      setErrMsg('No Server Response');
    } else if(error.response?.status === 422){
      setErrMsg(error.response.data.message);
    }else if(error.response?.status === 401 ){
      setErrMsg('Unauthorized')
    }else if(error.response?.status === 404){
      setErrMsg(error.response.data.message)
    }
    else{
      setErrMsg('Login Failed')
    }
    errRef.current.focus();
   }

  
  }
  useEffect(()=>{
    userRef.current.focus();
  })
  useEffect(()=>{
    setErrMsg('');
    
  }, [email, password])
   
  
  return (
    
    <div className='px-5 py-3'>
       <Typography gutterBottom sx={{fontWeight:'bold', fontSize:'30px', color:'#fff',
        textAlign:'center'}} >
        Admin Login
        </Typography>
        <Typography ref={errRef} aria-live="assertive" sx={{textAlign:'center', color:'#ee0000', p:'5px'}}>
          {errMsg}
          </Typography>

       <form onSubmit={submitHandler}>
        <Grid container rowSpacing={3} sx={{p:'5px'}}>
            <Grid item xs={12}> 
             <TextField fullWidth label="Email" id="userNumber" ref={userRef}
             onChange={(e) =>setEmail(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
             <TextField fullWidth label="Password" id="fullWidth"
              onChange={(e) =>setPassword(e.target.value)} type='password'/>
            </Grid>
           </Grid>
           <Box sx={{textAlign:'center'}}>
           <Button type='submit' size='large' variant='contained' sx={{ mt:2, color:'#fff'}} >
                Login
            </Button> 
           </Box>
            
       </form>
    </div>
  )

}

export default Login