import React from 'react'
import './MainDash.css';
import Cards from '../Cards/Cards'
import Table from '../Table/Table';
import { Typography } from '@mui/material';

const MainDash = () => {
  return (
    <div className='main-dash'>
      <Typography component="h2" variant="h4" sx={{textAlign:'center', py:'1rem'}}>Admin Dashboard</Typography>
      <Cards/>
      <Table/>
    </div>
  )
}

export default MainDash
