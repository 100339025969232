import React,{useEffect, useState} from 'react'
import SideBar from '../Components/SideBar/SideBar';
import axios from '../Components/axios';
import { Grid, TableContainer, TableCell, TableRow, TableBody,
    TableHead, Paper, Table, Button, Typography, TablePagination, } from '@mui/material';
import { Link } from 'react-router-dom';

const url = '/items'
const Products = () => {
const [loading, setLoading] = useState(true)
const [products, setProducts] = useState([]) 
const [errMsg, setErrMsg] = useState('')
const [pg, setpg] = useState(0);
const [rpg, setrpg] = useState(5);

function handleChangePage(event, newpage) {
    setpg(newpage);
}
function handleChangeRowsPerPage(event) {
    setrpg(parseInt(event.target.value, 10));
    setpg(0);
}

const fetchProducts = async ()=>{
    const savedUser = localStorage.getItem('user')
    await axios(url, 
        {headers:{
        Authorization: `Bearer ${JSON.parse(savedUser).token}`
    }}
    )
     .then((response) => {
      const products=   response?.data?.data
      setLoading(false)
      setProducts(products)
     })
     .catch( (error) => { 
     setErrMsg(error.response.message)
 })
 }

 useEffect(()=>{
    fetchProducts()
 }, [])

 const handleDelete = (product)=>{
  if(!window.confirm('Are you sure to delete this item?')){
    return
  }
 axios.delete(`items/${product.id}`, {headers:{
  'Content-type' : 'application/json',
  'Accept': 'application/json',
  Authorization: `Bearer ${JSON.parse(localStorage.getItem('user')).token}`
}}).then(()=>{
  fetchProducts()
})
 }
  return (
    <Grid container sx={{display:'flex', justifyContent:'space-between', px:'1rem'}}>
    <Grid item sx={{justifyContent:'start'}} xs={2}>
    <SideBar/>
    </Grid>  
    <Grid item xs={9} sx={{my:'3rem'}}>
    <Typography sx={{textAlign:'center', color:'red'}}>{errMsg}</Typography>
    <Typography sx={{textAlign:'center', color:'#fff' }} gutterBottom component="h2" variant="p">
      Store Items
      </Typography>
    <Paper>
     <TableContainer component={Paper}>
       <Table sx={{ minWidth: 650, }} aria-label="simple table" >
         <TableHead>
           <TableRow>
             <TableCell>Name</TableCell>
             <TableCell align="center">Description</TableCell>
             <TableCell align="center">Price</TableCell>
             <TableCell align="center">Image</TableCell>
             <TableCell align="center">Quantity</TableCell>
             <TableCell align="center">Edit</TableCell>
            <TableCell align="center">Delete</TableCell>
           </TableRow>
       </TableHead>
       {loading ? 
       <TableBody>
       <TableRow>
        <TableCell>Loading... </TableCell>
        </TableRow> 
        </TableBody>:
        <TableBody>
    {products.slice(pg * rpg, pg * rpg + rpg).map(product=>{
        return (   
        <TableRow key={product.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell>{product.name}</TableCell>
            <TableCell>{product.description}</TableCell>
             <TableCell>₦{product.price}</TableCell>
            <TableCell>
            <img src={product.image_url} alt="Product Avatar"
             style={{height:'3rem', width:'3rem', margin:'5px auto'}}/> 
             </TableCell>
             <TableCell>{product.quantity}</TableCell>
             <TableCell>
                <Link to={`/items/${product.id}`} style={{backgroundColor:'#2e7d32', 
                padding:'10px 15px', borderRadius:'5px', color:'#fff', textTransform:'uppercase'}}>Edit </Link>
             </TableCell>
             <TableCell>
                <Button type="button" color='error' variant='contained' 
                onClick={ev=>handleDelete(product)}>
                   Delete
                  </Button>
             </TableCell>
        </TableRow>
        )
    })}
    </TableBody>
    }
    </Table>
    </TableContainer>
<TablePagination
rowsPerPageOptions={[5, 10, 25]}
component="div"
count={products.length}
rowsPerPage={rpg}
page={pg}
onPageChange={handleChangePage}
onRowsPerPageChange={handleChangeRowsPerPage}
/>
    </Paper>
  </Grid>
  </Grid>
  )
}

export default Products
