import React,{useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from '../Components/axios';
import SideBar from '../Components/SideBar/SideBar';
import { Box, Grid, Table, Typography, TableContainer,
     TableCell, TableHead,TableBody, Paper, TableRow } from '@mui/material';
const SingleOrder = () => {
    const {id} = useParams();
    const [order, setOrder] = useState([])
    const [errMsg, setErrMsg] = useState('')
    const [channel, setChannel] = useState([])
    const [items, setItems] = useState([])

    const fetchOrder= async ()=>{
        const savedUser = localStorage.getItem('user')
        await axios(`orders/${id}`, {headers :{
            'Accept':'application/json',
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${JSON.parse(savedUser).token}`
        },
    })
        .then((items)=>{
            // console.log(items)
            setOrder(items.data.data)
            setChannel(items.data.data.channels)
            setItems(items.data.data.items)
        }).catch((error)=>{
            setErrMsg(error.message)
        })
    }
    useEffect(()=>{
        fetchOrder()
    }, [])
  return (

       
    <Grid container spacing={2} sx={{justifyContent:'space-between', display:'flex', px:'1rem'}}>
        <Grid item xs={2}>
            <SideBar/>
        </Grid>
        <Grid item xs={9} >
        <Typography sx={{textAlign:'center'}} gutterBottom component='h2' variant='p'>
            Order Details
            </Typography>
        <Paper>
       <TableContainer container={Paper}>
        <Table sx={{  maxWidth: '90%' }} aria-label="simple table" >
        <TableHead>
            <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Item(s) Details</TableCell>
                <TableCell align="center">Total Amount</TableCell>
                <TableCell align="center">Payment Method</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            <TableRow>
                    <TableCell align="center">{order.created_at}</TableCell>
                    <TableCell>
                    {items.map((item)=>(
                    <Box key={item.id}>
                    <Typography sx={{fontSize:'15px'}} gutterBottom >{item.name}</Typography>
                    <Typography sx={{fontSize:'15px'}} gutterBottom>Qty: <b>{item.quantity}</b></Typography>
                    <Typography sx={{fontSize:'15px'}} gutterBottom>Total Amount: <b>₦{item.quantity * item.price}</b></Typography>
                    </Box>
                    ))}
                    </TableCell>
                    <TableCell align="center">₦{order.amount}</TableCell>
                    { channel.map((channel)=>
                    (<TableCell align="center" key={channel.id}>{channel.channel}</TableCell>))}
                    
            </TableRow>
            </TableBody>
      </Table>
        </TableContainer>
        </Paper>
        </Grid>
        
    </Grid>
  )
}

export default SingleOrder