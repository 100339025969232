import React,{useState, useEffect} from 'react'
import {Grid, Paper, TableHead, TableContainer, Typography,
     Table, TableBody, TableCell, TablePagination, Box,
     TableRow,
     TextField,} from '@mui/material'
import SideBar from '../Components/SideBar/SideBar'
import { Link, } from 'react-router-dom'
import axios from '../Components/axios';
import Pagination from '../Components/Pagination';
const url ='/orders'
const Order = () => {
  const [products, setProducts] = useState([]);
const [loading, setLoading] = useState('')
const [errMsg, setErrMsg] = useState('')

const [currentPage, setCurrentPage] = useState(1)
    const [recordsPerPage] = useState(10)
    const [searchOrder, setSearchOrder] = useState(0);
    const [pg, setpg] = useState(0);
    const [rpg, setrpg] = useState(5);

    function handleChangePage(event, newpage) {
      setpg(newpage);
  }
  function handleChangeRowsPerPage(event) {
      setrpg(parseInt(event.target.value, 10));
      setpg(0);
  }

const fetchProducts = async ()=>{
    const savedUser = localStorage.getItem('user')
    await axios(url, 
        {headers:{
        Authorization: `Bearer ${JSON.parse(savedUser).token}`
    }}
    )
     .then((response) => {
      const products =   response?.data.data
      setLoading(false)
      setProducts(products)
      console.log(products)
     })
     .catch( (error) => { 
     setErrMsg(error.response.message)
 })
 }
 useEffect(()=>{
    fetchProducts()
 }, [])

const searchOrders = !searchOrder ? products : 
products.filter(order => order.amount.includes(searchOrder))

  return (
    <Grid container sx={{display:'flex', justifyContent:'space-between', px:'1rem'}}>
    <Grid item sx={{justifyContent:'start'}} xs={2}>
    <SideBar/>
    </Grid>  
    <Grid item xs={9} sx={{my:'3rem'}}>
    <Typography sx={{textAlign:'center', color:'red'}}>{errMsg}</Typography>
    <Typography sx={{textAlign:'center', color:'#fff' }} gutterBottom component="h2" variant="p">
      Store Orders
      </Typography>
      <Box sx={{display: 'flex', margin:'2rem 0', }}>
        <TextField name='searchOrder' id="searchOrder" label="Search By Price" type='number'
         fullWidth onChange={(e)=>setSearchOrder(e.target.value)}/>
      </Box>
    <Paper>
     <TableContainer component={Paper}>
       <Table sx={{ minWidth: 650, }} aria-label="simple table" >
         <TableHead>
           <TableRow>
             <TableCell align="center">Sale Date</TableCell>
             <TableCell align="center">Price</TableCell>
             <TableCell align="center">Info</TableCell>
           </TableRow>
       </TableHead>
       {loading ? 
       <TableBody>
       <TableRow>
        <TableCell align="center">Loading... </TableCell>
        </TableRow> 
        </TableBody>:
        <TableBody>
    {searchOrders.map(product=>{
        return (   
        <TableRow key={product.id} sx={{ '&:last-child td, &:last-child th': { border: 0 }}}>
            <TableCell align="center">{product.created_at} </TableCell>
             <TableCell align="center">₦{product.amount}</TableCell>
             <TableCell align="center">
                <Link to={`/orders/${product.id}`} style={{backgroundColor:'#2e7d32', 
                padding:'10px 15px', borderRadius:'5px', color:'#fff', textTransform:'capitalize'}}>
                 Learn More </Link>
             </TableCell>
        </TableRow>
        )
    })}
    </TableBody>
    }
    </Table>
    </TableContainer>
 <TablePagination
    rowsPerPageOptions={[5, 10, 25]}
    component="div"
    count={products.length}
    rowsPerPage={rpg}
    page={pg}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
/> 
{/* <Pagination
nPages={nPages}
currentPage={currentPage}
setCurrentPage={setCurrentPage}
products={products}

/> */}
    </Paper>
  </Grid>
  </Grid>
  )
}

export default Order