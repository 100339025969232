import { Typography, Grid, TextField, Button, Box, MenuItem, Select, InputLabel } from '@mui/material';
import axios from '../Components/axios';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import SideBar from '../Components/SideBar/SideBar';
import { useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
const SingleProduct = () => {
const [category, setCategory ]= useState([])
const [option, setOption] = useState('')
const [input, setInput] = useState(
    {
        name: '',
        description:'', 
        reference:'',
        image_url:'',
        store_id:'',
        quantity: '',
        category_id:'',
        class:'',
        price:'',
        cost:'',
        tax:''
    }
) 

const [errMsg, setErrMsg]=useState('')
    const {id} = useParams();
    const fetchCategory = async ()=>{
        const savedUser = localStorage.getItem('user')
        await axios('item-categories', 
            {headers:{
            Authorization: `Bearer ${JSON.parse(savedUser).token}`
        }}
        )
         .then((response) => {
          const categories= response?.data?.data
          setCategory(categories)
         })
         .catch( (error) => {
         setErrMsg(error.message)
     })
    }
    const fetchProduct = async ()=>{
        const savedUser = localStorage.getItem('user')
        await axios(`items/${id}`, 
            {headers:{
            Authorization: `Bearer ${JSON.parse(savedUser).token}`
        }}
        )
         .then((response) => {
          const product=   response?.data?.data
          setInput(product)
         })
         .catch( (error) => {
         setErrMsg(error.message)
     })
     }
useEffect( ()=>{
    fetchProduct()
    fetchCategory()
}, [])

const changeHandler = (e)=>{
    const name= e.target.name;
    const value= e.target.value;
    setInput({...input, [name]:value})
}
const categoryHandler= (e)=>{
    setOption(e.target.value)
}

const submitHandler = (e)=>{
    e.preventDefault();
    const data={
        name:input.name, 
        description: input.description,
        reference:input.reference,
        image_url: input.image_url,
        store_id: input.store_id,
        quantity:input.quantity,
        class:input.class,
        category_id: option,
        price: input.price,
        cost: input.cost,
        tax:input.tax
    }
    const savedUser = localStorage.getItem('user')
    const toastOptions ={
        autoClose: 500,
        pauseOnHover:true
     }
     axios.put(`items/${id}`, data, {
        headers:{
            'Accept': 'application/json',
            Authorization: `Bearer ${JSON.parse(savedUser).token}`
        }
    }).then(item=>{
        // console.log(item.data.data)
        setInput('')
        toast(`Updated ${item.data.data.name} successfully`, toastOptions )
    }).catch(error=>{
        console.log(error)
        if (!error?.response) {
            setErrMsg('No Server Response');
          } else if(error.response?.status === 422){
            setErrMsg(error.response.data.message);
          }else if(error.response?.status === 401 ){
            setErrMsg('Unauthorized')
          }else if(error.response?.status === 404){
            setErrMsg(error.response.data.message)
          }
          else if(error.response?.status === 500){
            setErrMsg("Some input data are either too long or not appropriate")
          }
          else{
            setErrMsg('Upload Failed')
          }
    })
}
  return (
    <Grid container sx={{justifyContent:'space-between'}}>
        <Grid item xs={2}>
            <SideBar/>
        </Grid>
        <Grid item xs={9}>
        <form onSubmit={submitHandler}>
            <Typography variant='p' component='h2' sx={{textAlign:'center', mt:"3rem", color:'#000'}}>
                Update The Item 
            </Typography>
            <Typography variant='p' component='p' sx={{color:'red', p:'5px', textAlign:'center'}}>
                {errMsg}
            </Typography>
        <Grid rowSpacing={3} sx={{p:'5px'}} columnSpacing={3} container>
            <Grid item xs={5}>
            <TextField fullWidth label="Item Name" onChange={changeHandler} id="name" name='name'
             value={input.name}/>
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Description" onChange={changeHandler} id="description" 
           value={input.description} name='description'/>
           </Grid>
            <Grid item xs={5}> 
            <TextField fullWidth label="Reference" onChange={changeHandler} id="reference"
             name="reference" value={input.reference}/>
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Image Url" onChange={changeHandler} id="image_url"
             value={input.image_url} name="image_url"/>
            </Grid>
            <Grid item xs={5}>
             <TextField fullWidth label="Store ID" onChange={changeHandler} id="store_id" 
            value={input.store_id} name="store_id"/>
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Quantity" onChange={changeHandler} id="quantity" 
           value={input.quantity} name="quantity"/>
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="class" onChange={changeHandler} id="class" 
           value={input.class} name='class' />
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Price" onChange={changeHandler} id="price" name='price'
            value={input.price} />
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Cost" onChange={changeHandler} id="cost" name='cost'
            value={input.cost} />
            </Grid>
            <Grid item xs={5}>
            <TextField fullWidth label="Tax" onChange={changeHandler} id="tax" name='tax' 
            value={input.tax}/>
            </Grid>
            <Grid item xs={5}>
            <InputLabel id="category_id">Category ID</InputLabel>
                <Select
                    labelId="category_id"
                    id="category_id"
                    label="Category ID"
                    value={option}
                    onChange={categoryHandler}
                    sx={{width:'100%'}}
                >
                    {category.map((cat)=>{
                        return <MenuItem value={cat.id} key={cat.id}> {cat.name}</MenuItem>
                    })}
                </Select>
             </Grid>
        </Grid>
            <Box sx={{justifyContent:'center', textAlign:'center'}}>
            <Button type='submit' variant='contained' color='success' sx={{my:2}}>
                Post Item
            </Button>
            </Box>
        </form>
        </Grid>
        <ToastContainer/>
    </Grid>
  )
}

export default SingleProduct